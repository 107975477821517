import {
  DailyVideo,
  useVideoTrack,
  useScreenShare,
  useParticipantProperty,
} from "@daily-co/daily-react";
import { PiSwap } from "react-icons/pi";

export default function Tile({
  id,
  isScreenShareType,
  smallerVersion,
  onClickSmallShare,
}) {
  const videoState = useVideoTrack(id);
  const { isSharingScreen } = useScreenShare();
  const screenSharingTrack = useParticipantProperty(id, "screenVideoTrack");

  if (!isScreenShareType) {
    return (
      <div className="relative w-auto h-auto rounded-md border border-gray-100 shadow-lg shadow-gray-200">
        <div className="aspect-w-16 aspect-h-9">
          <DailyVideo
            type={"video"}
            fit="contain"
            sessionId={id}
            className="object-contain w-full h-full"
          />
        </div>
      </div>
    );
  }
  if (!smallerVersion && isScreenShareType && screenSharingTrack) {
    return (
      <div
        className="rounded-tl-lg rounded-tr-lg h-[35rem] w-full"
        style={{
          backgroundImage: "url('/photos/bg-2.png')",
          backgroundSize: "cover",
        }}
      >
        <DailyVideo
          type="screenVideo"
          fit="contain"
          sessionId={id}
          className="object-contain w-full h-full"
        />
      </div>
    );
  }
  if (smallerVersion && isScreenShareType && screenSharingTrack) {
    return (
      <div
        onClick={onClickSmallShare}
        className="relative w-auto h-auto rounded-lg border border-gray-100 shadow-lg cursor-pointer shadow-gray-200 group"
      >
        {/* Hover overlay */}
        <div className="absolute inset-0 bg-black rounded-lg opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>

        {/* Icon */}
        <PiSwap className="absolute inset-0 m-auto text-4xl text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100" />

        {/* Content */}
        <div className="rounded-lg aspect-w-16 aspect-h-9">
          <DailyVideo
            type="screenVideo"
            fit="contain"
            sessionId={id}
            className="object-contain w-full h-full rounded-md"
          />
        </div>
      </div>
    );
  }
}

// <PiSwap />
