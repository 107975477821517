const apiVisitorFilters = async (visitor_id) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/organizations/${org_id}/visitors/filters`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Fetching visitors filters failed");
    }

    const data = await response.json();
    console.log(data);
    return data; // Return fetched data
  } catch (error) {
    console.error("Error fetching visitors filters:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiVisitorFilters;
