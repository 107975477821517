import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent } from "../../components/card";
import { Label } from "../../components/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/select";
import { Textarea } from "../../components/textarea";
import { Input } from "../../components/input";
import apiDiscordData from "../WorkflowApis/apiDiscordData";
import { FaChevronDown } from "react-icons/fa";
import apiWorkflowsFields from "../WorkflowApis/apiWorkflowsFields";

export default function StepDiscord({ workflowData, setWorkflowData }) {
  const defaultMessage1 = `🚨 New Visitor 🚨:
{{visitor.name}}
Just opened {{visitor.current_page}}
Visited: {{visitor.total_browsing_sessions}} times
📍{{visitor.location.city}} :flag-{{visitor.location.country}}: - {{visitor.ip}} - {{visitor.browser.name}}, {{visitor.device.name}}, {{visitor.device.type}}`;

  const defaultMessage2 = "☎️ {{visitor.name}} is calling 👋";
  const [discordData, setDiscordData] = useState(null);
  const [workflowFields, setWorkflowFields] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [initalchanel, setInitalchanel] = useState(null);
  const [error, setError] = useState(null);
  const isPopup = workflowData.action.type === "popup";
  const [customMessage, setCustomMessage] = useState(() => {
    if (workflowData.action.notification?.message) {
      return workflowData.action.notification.message;
    } else if (workflowData.action.message) {
      return workflowData.action.message;
    } else {
      const defaultMessage =
        workflowData.action.type === "popup"
          ? defaultMessage2
          : defaultMessage1;
      // Apply the default message to the workflow data
      if (isPopup) {
        setWorkflowData((prevData) => ({
          ...prevData,
          action: {
            ...prevData.action,
            notification: {
              ...prevData.action.notification,
              message: defaultMessage,
            },
          },
        }));
      } else {
        setWorkflowData((prevData) => ({
          ...prevData,
          action: {
            ...prevData.action,
            message: defaultMessage,
          },
        }));
      }
      return defaultMessage;
    }
  });
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");

  const setInitalchanelData = (data) => {
    if (isPopup) {
      if (workflowData.action?.notification?.channel_id) {
        const initialItem = [...(data || [])].find(
          (item) => item.id === workflowData.action?.notification?.channel_id
        );
        if (initialItem) {
          setInitalchanel(initialItem.name);
        }
      }
    } else {
      if (workflowData.action.channel_id) {
        const initialItem = [...(data || [])].find(
          (item) => item.id === workflowData.action.channel_id
        );
        if (initialItem) {
          setInitalchanel(initialItem.name);
          console.log(initialItem.name);
        }
      }
    }
  };

  useEffect(() => {
    const fetchDiscordData = async () => {
      try {
        const data = await apiDiscordData();
        setDiscordData(data);

        setInitalchanelData(data);
      } catch (error) {
        console.error("Error fetching Discord data:", error);
        setError("Failed to fetch Discord data. Please try again later.");
      }
    };

    const fetchWorkflowFields = async () => {
      try {
        const data = await apiWorkflowsFields();
        setWorkflowFields({
          ...Object.fromEntries(
            Object.entries(data.fields).map(([key, value]) => [
              `visitor.${key}`,
              value,
            ])
          ),
        });
        console.log("Workflow fields:", data);
      } catch (error) {
        console.error("Error fetching workflow fields:", error);
        setError((prevError) =>
          prevError
            ? `${prevError} Also failed to fetch workflow fields.`
            : "Failed to fetch workflow fields. Please try again later."
        );
      }
    };

    fetchDiscordData();
    fetchWorkflowFields();
  }, [
    workflowData.action.channel_id,
    workflowData.action?.notification?.channel_id,
  ]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (item) => {
    console.log(item);
    setSelectedItem(item);
    setIsOpen(false);
    if (isPopup) {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          notification: {
            ...prevData.action.notification,
            channel_id: item.id,
          },
        },
      }));
    } else {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          channel_id: item.id,
        },
      }));
    }
  };

  const renderDropdownItems = () => {
    const items = filteredItems();

    return items.map((item) => (
      <li
        key={item.id}
        className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
          item.id.endsWith("-header") ? "font-semibold bg-gray-50" : ""
        }`}
        onClick={() => !item.id.endsWith("-header") && handleSelect(item)}
        role={item.id.endsWith("-header") ? "presentation" : "option"}
      >
        {item.name}
      </li>
    ));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const newCursorPosition = e.target.selectionStart;
    setCustomMessage(value);
    setCursorPosition(newCursorPosition);

    if (isPopup) {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          notification: { ...prevData.action.notification, message: value },
        },
      }));
    } else {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          message: value,
        },
      }));
    }

    const lastTwoChars = value.slice(newCursorPosition - 2, newCursorPosition);
    const textAfterCursor = value.slice(newCursorPosition);
    const hasClosingBrackets = textAfterCursor.includes("}}");

    if (lastTwoChars === "{{" && !hasClosingBrackets) {
      setSuggestions(Object.keys(workflowFields || {}));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const beforeCursor = customMessage.slice(0, cursorPosition);
    const afterCursor = customMessage.slice(cursorPosition);
    const lastOpenBrackets = beforeCursor.lastIndexOf("{{");
    const newValue =
      beforeCursor.slice(0, lastOpenBrackets) +
      "{{" +
      suggestion +
      "}}" +
      afterCursor;
    setCustomMessage(newValue);
    setShowSuggestions(false);
    textareaRef.current.focus();

    if (isPopup) {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          notification: {
            ...prevData.action.notification,
            message: newValue,
          },
        },
      }));
    } else {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          message: newValue,
        },
      }));
    }

    const newCursorPosition = lastOpenBrackets + suggestion.length + 4;
    setTimeout(() => {
      textareaRef.current.setSelectionRange(
        newCursorPosition,
        newCursorPosition
      );
    }, 0);
  };

  const filteredItems = () => {
    if (!discordData) return [];

    const filtered = discordData.filter((channel) =>
      channel.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort the filtered items so that items starting with the search term come first
    filtered.sort((a, b) => {
      const aStartsWith = a.name
        .toLowerCase()
        .startsWith(searchTerm.toLowerCase());
      const bStartsWith = b.name
        .toLowerCase()
        .startsWith(searchTerm.toLowerCase());
      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;
      return 0;
    });

    return [
      { id: "channels-header", name: "Channels", type: "channel" },
      ...filtered.map((channel) => ({ ...channel, type: "channel" })),
    ];
  };

  return (
    <Card className="border-none shadow-none">
      <CardContent className="p-0">
        <h2 className="mb-4 text-lg font-semibold">
          Configure your Discord Message
        </h2>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="message-source">
              Where do you want to get the message?
            </Label>
            <div className="relative w-full">
              <button
                onClick={toggleDropdown}
                className="px-4 py-2 w-full text-left bg-white rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-700"
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                id="message-source"
              >
                {initalchanel
                  ? initalchanel
                  : selectedItem
                  ? selectedItem.name
                  : "Select a channel or user"}
                <FaChevronDown className="absolute top-3 right-4 w-5 h-5 text-gray-400" />
              </button>
              {isOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white rounded-md border border-gray-300 shadow-lg">
                  <Input
                    type="text"
                    placeholder="Search channels..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 w-full focus-visible:ring-0 focus-visible:outline"
                  />
                  <ul className="overflow-auto max-h-60" role="listbox">
                    {error ? (
                      <li className="px-4 py-2 text-red-500">{error}</li>
                    ) : discordData ? (
                      renderDropdownItems()
                    ) : (
                      <li className="px-4 py-2">Loading...</li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="custom-message">
              Configure your custom message
            </Label>
            <div className="relative">
              <Textarea
                id="custom-message"
                ref={textareaRef}
                value={customMessage}
                onChange={handleInputChange}
                placeholder="Type {{ to insert dynamic fields"
                className="w-full min-h-[100px]"
              />
              {showSuggestions && suggestions.length > 0 && (
                <ul className="overflow-auto absolute z-10 mt-1 w-full max-h-60 bg-white rounded-md border border-gray-300 shadow-lg">
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
