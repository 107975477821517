const apiRegister = async (registerData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(registerData),
      }
    );

    const data = await response.json();

    if (data.success) {
      console.log("Register and loged in successful:", data);
      localStorage.setItem("agentName", data.user.name);
      localStorage.setItem("email", data.user.email);
      localStorage.setItem("apikey", data.user.api_key);
      localStorage.setItem("orgid", data.user.org_id);
      localStorage.setItem("userid", data.user._id);
    }
    return { success: true, data }; // Return success and data
  } catch (error) {
    console.error("Error during register:", error);
    return { success: false, error }; // Return failure and error message
  }
};

export default apiRegister;
