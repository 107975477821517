const apiCheckForCalling = async () => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${org_id}/call_requests/`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Fetching existing workflow failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching existing workflow:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiCheckForCalling;
