import { useContext } from "react";
import Logo from "./Logo";
import MainNav from "./MainNav";
import { AppLayoutContext } from "../pages/AppLayout";
import { IoMdClose } from "react-icons/io";

// function Sidebar() {
//   const { sidebarOpen, setSidebarOpen } = useContext(AppLayoutContext);
//   return (
//     <div className="flex flex-col gap-8 p-4">
//       <div className="flex justify-between items-center">
//         <Logo />
//         {sidebarOpen && (
//           <button
//             onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
//             className="text-5xl"
//           >
//             <IoMdClose />
//           </button>
//         )}
//       </div>

//       <MainNav />
//     </div>
//   );
// }

// function Sidebar() {
//   const {
//     sidebarOpen,
//     setSidebarOpen,
//     sidebarHovered,
//     setSidebarHovered,
//     isMobile,
//   } = useContext(AppLayoutContext);

//   const sidebarClass = `
//     bg-white shadow-lg transition-all duration-300 ease-in-out
//     ${isMobile ? "fixed top-0 left-0 h-full z-11" : "relative"}
//     ${
//       isMobile && sidebarOpen
//         ? "w-60"
//         : isMobile
//         ? "w-0"
//         : sidebarHovered
//         ? "w-60"
//         : "w-16"
//     }
//   `;

//   const overlayClass = `
//     fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out backdrop-blur-sm
//     ${isMobile && sidebarOpen ? "opacity-100 visible" : "opacity-0 invisible"}
//   `;

//   return (
//     <>
//       <div
//         className={sidebarClass}
//         onMouseEnter={() => !isMobile && setSidebarHovered(true)}
//         onMouseLeave={() => !isMobile && setSidebarHovered(false)}
//       >
//         <div className="flex overflow-hidden flex-col p-4 h-full">
//           <div className="flex justify-between items-center mb-8">
//             <Logo small={!sidebarHovered && !isMobile} />
//           </div>
//           <MainNav expanded={sidebarHovered || (isMobile && sidebarOpen)} />
//         </div>
//       </div>
//       {isMobile && (
//         <div
//           className={overlayClass}
//           onClick={() => setSidebarOpen(false)}
//         ></div>
//       )}
//     </>
//   );
// }

function Sidebar() {
  const {
    sidebarOpen,
    setSidebarOpen,
    sidebarHovered,
    setSidebarHovered,
    isMobile,
  } = useContext(AppLayoutContext);

  const sidebarClass = `
    bg-white shadow-lg transition-all duration-300 ease-in-out
    ${isMobile ? "fixed top-0 left-0 h-full z-50" : "relative"}
    ${
      isMobile && sidebarOpen
        ? "w-60"
        : isMobile
        ? "w-0"
        : sidebarHovered
        ? "w-60"
        : "w-16"
    }
  `;

  const overlayClass = `
    fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out backdrop-blur-sm z-40
    ${isMobile && sidebarOpen ? "opacity-100 visible" : "opacity-0 invisible"}
  `;

  return (
    <>
      <div
        className={sidebarClass}
        onMouseEnter={() => !isMobile && setSidebarHovered(true)}
        onMouseLeave={() => !isMobile && setSidebarHovered(false)}
      >
        <div className="flex overflow-hidden flex-col p-4 h-full">
          <div className="flex justify-between items-center mb-8">
            <Logo small={!sidebarHovered && !isMobile} />
            {/* {isMobile && (
              <button
                onClick={() => setSidebarOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <IoMdClose size={24} />
              </button>
            )} */}
          </div>
          <MainNav expanded={sidebarHovered || (isMobile && sidebarOpen)} />
        </div>
      </div>
      {isMobile && (
        <div
          className={overlayClass}
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </>
  );
}

export default Sidebar;
