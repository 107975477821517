import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Input } from "../components/input";
import { Button } from "../components/button";
import { FiEdit, FiCheck } from "react-icons/fi";
import toast from "react-hot-toast";
import apiVisitorUpdate from "../Visitors/VisitorsApis/apiVisitorUpdate";

function VisitorNameInput({ initialName, visitorId, onNameUpdate }) {
  const [name, setName] = useState(initialName);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (inputRef.current) {
        const maxWidth = window.innerWidth < 510 ? 100 : 300;
        inputRef.current.style.maxWidth = `${maxWidth}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleEditToggle = () => {
    if (isEditing) {
      handleSave();
    } else {
      setIsEditing(true);
    }
  };

  const handleSave = async () => {
    if (name !== initialName) {
      try {
        await apiVisitorUpdate(visitorId, { name });
        onNameUpdate(name);
        toast.success("Visitor name updated successfully");
      } catch (error) {
        toast.error(`Error updating visitor name: ${error.message}`);
        setName(initialName);
      }
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <div className="flex items-center">
      <Input
        ref={inputRef}
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={!isEditing}
        className="min-w-[50px] transition-all duration-200 ease-in-out disabled:opacity-100 border-0 px-0"
        style={{
          width: `${Math.min(name.length * 8 + 10, 300)}px`,
          maxWidth: "calc(100vw < 510px ? 200px : 300px)",
        }}
      />
      <Button
        variant="ghost"
        size="icon"
        onClick={handleEditToggle}
        className="ml-2"
      >
        {isEditing ? (
          <FiCheck className="w-4 h-4" />
        ) : (
          <FiEdit className="w-4 h-4" />
        )}
      </Button>
    </div>
  );
}

export default VisitorNameInput;
