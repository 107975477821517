import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisitorsTable from "../Visitors/VisitorsTable";
import { RxHamburgerMenu } from "react-icons/rx";
import { useContext } from "react";
import { AppLayoutContext } from "./AppLayout";
import { Card } from "../components/card";
import { Button } from "../components/button";
import apiVisitors from "../Visitors/VisitorsApis/apiVisitors";
import VisitorsFiltersCard from "../Visitors/VisitorsFiltersCard";
import { Dialog, DialogContent, DialogTitle } from "../components/dialog";

function Visitors() {
  const { setSidebarOpen } = useContext(AppLayoutContext);
  const [isFilteringCardOpen, setIsFilteringCardOpen] = useState(false);
  const [totalVisitors, setTotalVisitors] = useState(null);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const data = await apiVisitors(1, 1);
        console.log(data);
        setTotalVisitors(data.total_visitors);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchVisitors();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-bold pointer-events-none">Visitors</p>
          {totalVisitors && <p> {totalVisitors} Visitors</p>}
        </div>

        <button
          className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-transparent hover:border-black active:border-black lg:hidden"
          onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
        >
          <RxHamburgerMenu />
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-end">
          <button
            className="px-5 py-2 font-semibold text-white bg-black rounded-lg"
            onClick={() => setIsFilteringCardOpen(true)}
          >
            Filters
          </button>
        </div>
        <VisitorsTable filters={filters} />
      </div>

      <Dialog open={isFilteringCardOpen} onOpenChange={setIsFilteringCardOpen}>
        <DialogContent className="p-0 rounded-lg">
          <DialogTitle className="hidden">Dialog</DialogTitle>
          <VisitorsFiltersCard
            onClose={() => setIsFilteringCardOpen(false)}
            setFilters={setFilters}
            filters={filters}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Visitors;
