import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SignUpForm({ children, isWaitList = false }) {
  useEffect(() => {
    const org_id = localStorage.getItem("orgid");
    if (org_id) {
      navigate("/dashboard");
    }
  }, []);

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="flex overflow-hidden flex-col h-screen">
      <header className="flex justify-between items-center p-3 border-b-2 sm:p-5">
        <div className="flex gap-2 justify-center items-center pl-2 sm:gap-4 sm:pl-6">
          <img src="/logo/logoSmaller.png" alt="logo" className="w-5 sm:w-7" />
          <p className="text-lg font-bold sm:text-2xl">Face2Face.io</p>
        </div>
        {isWaitList && (
          <button
            className="p-1 text-sm text-white bg-red-500 rounded-lg sm:p-2 sm:text-base"
            onClick={handleLogout}
          >
            Logout
          </button>
        )}
      </header>
      {children}
    </div>
  );
}
