// function Logo() {
//   return (
//     <img
//       className="w-[4.5rem] h-[4.5rem] mt-1 ml-5"
//       src="/logo/logo.png"
//       alt="logo"
//     />
//   );
// }

// export default Logo;

function Logo({ small }) {
  return (
    <div
      className={`flex items-center ${
        small ? "justify-center" : "justify-start"
      }`}
    >
      <img
        src="/logo/logo.png"
        alt="Logo"
        className={`${small ? "w-8 h-8" : "w-12 h-12"}`}
      />
    </div>
  );
}

export default Logo;
