import React, { useState, useEffect, useCallback } from "react";
import { LuStopCircle } from "react-icons/lu";
import { io } from "socket.io-client";
import { useDaily, DailyAudio, useParticipantIds } from "@daily-co/daily-react";
import Call from "./daily/Call";
import Tray from "./daily/Tray/Tray";
import toast from "react-hot-toast";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import Tile from "./daily/Tile";

const STATE_IDLE = "STATE_IDLE";
const STATE_CREATING = "STATE_CREATING";
const STATE_LEAVING = "STATE_LEAVING";

function CallingWindow({ visitorData }) {
  const [appState, setAppState] = useState(STATE_IDLE);
  const [initalStalking, setInitalStalking] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [watchUrl, setWatchUrl] = useState("");
  const [roomUrl, setRoomUrl] = useState("");
  const [callAccepted, setCallAccepted] = useState(false);
  const [socket, setSocket] = useState(null);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const callObject = useDaily();
  const {
    _id: visitorId,
    upscope_short_id: visitorUpscopeShortId,
    online,
  } = visitorData.visitor;
  const apikey = localStorage.getItem("apikey");
  const orgid = localStorage.getItem("orgid");
  const userid = localStorage.getItem("userid");
  const agentName = localStorage.getItem("agentName");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callRequest = queryParams.get("callRequest");

  const handleCallResponse = useCallback(
    debounce((data) => {
      if (!data.call_response) {
        if (callObject) {
          setRoomUrl(null);
        }
        setAppState(STATE_IDLE);
        setIsOpen(() => true);
        toast.error("Call declined!");
      }
    }, 500),
    []
  );

  const handleCallEnded = useCallback(
    debounce((data) => {
      if (callObject) {
        setRoomUrl(null);
      }
      setAppState(STATE_IDLE);
      setCallAccepted(false);
      setIsOpen(() => true);
      toast.success("Call ended!");
      queryParams.delete("callRequest");
      const newQueryString = queryParams.toString();
      const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

      window.history.replaceState(null, "", newUrl);
    }, 500),
    []
  );

  const handleJoinCall = useCallback(
    debounce((data) => {
      setAppState(STATE_CREATING);
      if (data.room_url) {
        if (callObject) {
          void callObject.join({ url: data.room_url });
        }
        setRoomUrl(data.room_url);
        setCallAccepted(true);
      } else {
        console.error("No room URL provided.");
        setAppState(STATE_IDLE);
      }
    }, 500),
    []
  );

  useEffect(() => {
    const roomId = visitorId;

    const newSocket = io(process.env.REACT_APP_API_URL, {
      query: { target_id: roomId },
    });

    setSocket(newSocket);

    newSocket.on("f2f_call_response", handleCallResponse);
    newSocket.on("f2f_call_ended", handleCallEnded);
    newSocket.on("join_f2f_call", handleJoinCall);
    newSocket.on("error", (data) => {
      console.log(data.message);
    });

    newSocket.on("screen_share_on", (data) => {
      if (data.f2fVisitorID === visitorId && data.userType === "agent") {
        setIsScreenSharing(true);
      }
    });

    newSocket.on("screen_share_off", (data) => {
      if (data.f2fVisitorID === visitorId && data.userType === "agent") {
        setIsScreenSharing(false);
      }
    });

    if (callRequest) {
      newSocket.emit("start_f2f_call", {
        agentName: agentName,
        f2fVisitorID: visitorId,
        agentId: userid,
      });

      setIsOpen(() => false);
    }

    return () => {
      newSocket.off("f2f_call_response");
      newSocket.off("f2f_call_ended");
      newSocket.off("join_f2f_call");
      newSocket.off("error");
      newSocket.off("screen_share_on");
      newSocket.off("screen_share_off");
    };
  }, [location.search]);

  const remoteParticipantIds = useParticipantIds({ filter: "remote" });

  if (isOpen2) {
    return (
      <div className="relative rounded-3xl shadow-md">
        {watchUrl && (
          <>
            <div
              className={`${screenShareOn && "hidden"} relative w-full`}
              style={{ paddingTop: "56.25%" }}
            >
              <div
                className="absolute inset-0 bg-center bg-cover"
                style={{
                  backgroundImage: "url('/photos/bg-2.png')",
                }}
              >
                <iframe
                  src={watchUrl}
                  title="mainScreen"
                  className="absolute inset-0 w-full h-full"
                />
                {!screenShareOn && !callAccepted && (
                  <div
                    className="absolute inset-0 bg-transparent"
                    style={{ zIndex: 10 }}
                  />
                )}
              </div>
            </div>

            {screenShareOn &&
              remoteParticipantIds.length > 0 &&
              remoteParticipantIds.map((id) => (
                <React.Fragment key={id}>
                  <Tile id={id} isScreenShareType={true} />
                </React.Fragment>
              ))}
          </>
        )}
        <div className="bg-white rounded-br-lg rounded-bl-lg">
          {isOpen ? (
            <BeforeCall
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setIsOpen2={setIsOpen2}
              setAppState={setAppState}
              appState={appState}
              setRoomUrl={setRoomUrl}
              visitorId={visitorId}
              callAccepted={callAccepted}
              setCallAccepted={setCallAccepted}
              setSocket={setSocket}
              socket={socket}
            />
          ) : (
            <DuringCall
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              roomUrl={roomUrl}
              setRoomUrl={setRoomUrl}
              appState={appState}
              setAppState={setAppState}
              socket={socket}
              visitorId={visitorId}
              setScreenShareOn={setScreenShareOn}
              screenShareOn={screenShareOn}
              remoteParticipantIds={remoteParticipantIds}
              callAccepted={callAccepted}
              isScreenSharing={isScreenSharing}
              setIsScreenSharing={setIsScreenSharing}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <StartWindow
        setIsOpen2={setIsOpen2}
        setWatchUrl={setWatchUrl}
        apikey={apikey}
        orgid={orgid}
        userid={userid}
        visitorUpscopeShortId={visitorUpscopeShortId}
        online={online}
        agentName={agentName}
        initalStalking={initalStalking}
        setInitalStalking={setInitalStalking}
      />
    );
  }
}

function BeforeCall({ setIsOpen2, visitorId, socket, setIsOpen, isOpen }) {
  const userid = localStorage.getItem("userid");
  const agentName = localStorage.getItem("agentName");

  const handleConnect = () => {
    socket.emit("f2f_call_request", {
      agentName: agentName,
      f2fVisitorID: visitorId,
      user_id: userid,
    });
    setIsOpen(() => false);
  };

  return (
    <div className="flex flex-col gap-4 min-[330px]:flex-row items-center justify-between min-[500px]:h-16  px-4 py-2 min-[500px]:py-0 ">
      <button
        className="border-0 text-black font-semibold bg-green-300 px-2 py-[0.7rem] rounded-lg hover:bg-green-400 "
        onClick={handleConnect}
      >
        <p>Talk Face-to-Face 👋</p>
      </button>
      <button
        className="border-0 text-black text-2xl font-semibold bg-red-300 px-3 py-[0.7rem] rounded-lg hover:bg-red-400"
        onClick={() => setIsOpen2((isOpen) => !isOpen)}
      >
        {<LuStopCircle />}
      </button>
    </div>
  );
}

function DuringCall({
  setRoomUrl,
  appState,
  setAppState,
  socket,
  visitorId,
  setScreenShareOn,
  screenShareOn,
  remoteParticipantIds,
  callAccepted,
  isScreenSharing,
  setIsScreenSharing,
}) {
  const agentName = localStorage.getItem("agentName");

  const callObject = useDaily();

  const startLeavingCall = useCallback(() => {
    if (!callObject) {
      setRoomUrl(null);
      return;
    }
    setAppState(STATE_LEAVING);
    if (isScreenSharing) {
      socket.emit("screen_share_off", {
        f2fVisitorID: visitorId,
        userType: "agent",
      });
    }
    callObject.leave().then(() => {
      setRoomUrl(null);
      setAppState(STATE_IDLE);
    });
    setRoomUrl(null);

    socket.emit("f2f_call_ended", {
      agentName: agentName,
      user_id: visitorId,
    });
  }, [callObject, appState, isScreenSharing, socket, visitorId, agentName]);

  return (
    <div className="flex flex-col gap-1 p-4">
      <Call setScreenShareOn={setScreenShareOn} screenShareOn={screenShareOn} />
      <Tray
        setScreenShareOn={setScreenShareOn}
        screenShareOn={screenShareOn}
        remoteParticipantIds={remoteParticipantIds}
        startLeavingCall={startLeavingCall}
        callAccepted={callAccepted}
        isScreenSharing={isScreenSharing}
        setIsScreenSharing={setIsScreenSharing}
        socket={socket}
        visitorId={visitorId}
      />
      <DailyAudio />
    </div>
  );
}

function StartWindow({
  setIsOpen2,
  setWatchUrl,
  apikey,
  orgid,
  userid,
  visitorUpscopeShortId,
  online,
  initalStalking,
  setInitalStalking,
  agentName,
}) {
  useEffect(() => {
    if (online && !initalStalking) {
      startStalkingSession();
      setInitalStalking(true);
    }
  }, []);

  const startStalkingSession = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/upscope/start-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apikey,
            "X-Org-Id": orgid,
            "X-User-Id": userid,
          },
          body: JSON.stringify({
            visitorId: visitorUpscopeShortId,
            agentName: agentName,
          }),
        }
      );

      const result = await response.json();
      setIsOpen2(true);
      setWatchUrl(result.watch_url);

      console.log("Data fetched:", result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return online && !initalStalking ? (
    <></>
  ) : !online ? (
    <></>
  ) : (
    <div className="bg-white rounded-lg">
      <button
        className="px-6 py-2 m-3 bg-gray-200 rounded-3xl border border-transparent hover:border-black active:border-black"
        onClick={() => startStalkingSession()}
      >
        <p>Activate stalking 👀</p>
      </button>
    </div>
  );
}

export default CallingWindow;
