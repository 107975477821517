import React, { useState, useCallback } from "react";
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
} from "@daily-co/daily-react";

import Tile from "./Tile";

export default function Call({ setScreenShareOn, screenShareOn }) {
  const remoteParticipantIds = useParticipantIds({ filter: "remote" });

  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantIds.length < 1;

  const handleScreenSwap = () => {
    setScreenShareOn((prev) => !prev);
  };

  const renderCallScreen = () => (
    <div className="grid grid-cols-1 min-[450px]:grid-cols-2  min-[615px]:grid-cols-3 gap-x-4 gap-y-4 ">
      {localSessionId && (
        <Tile
          id={localSessionId}
          isLocal
          isAlone={isAlone}
          isScreenShareType={false}
        />
      )}

      {remoteParticipantIds.length > 0 &&
        remoteParticipantIds.map((id) => (
          <React.Fragment key={id}>
            <Tile id={id} isScreenShareType={false} />
          </React.Fragment>
        ))}

      {remoteParticipantIds.length > 0 &&
        remoteParticipantIds.map((id) => (
          <React.Fragment key={id}>
            <Tile
              id={id}
              isScreenShareType={true}
              smallerVersion={true}
              onClickSmallShare={handleScreenSwap}
            />
          </React.Fragment>
        ))}
    </div>
  );

  return renderCallScreen();
}
