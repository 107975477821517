import React, { useEffect, useState } from "react";
import { Button } from "../components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../components/dropdown-menu";

import { FiChevronDown, FiCopy } from "react-icons/fi";
import toast from "react-hot-toast";
import apiDiscordData from "../Workflows/WorkflowApis/apiDiscordData";
import apiSlackData from "../Workflows/WorkflowApis/apiSlackData";

const scrollbarStyles = `
  .scrollbar-custom::-webkit-scrollbar {
    width: 8px;
  }
  .scrollbar-custom::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .scrollbar-custom::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ChannelsDropdown = ({ listFor }) => {
  const [discordData, setDiscordData] = useState([]);
  const [slackData, setSlackData] = useState([]);
  const [dropDownData, setDropDownData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Discord data
        const discordResult = await apiDiscordData();
        setDiscordData(discordResult);
        console.log(discordResult);
        // Fetch Slack data
        const slackResult = await apiSlackData();
        setSlackData(slackResult);

        const formatedDiscordData = discordResult.map((item) => ({
          id: item.id,
          name: item.name,
        }));

        const formatedSlackData = [
          ...(slackResult?.channels || []),
          ...(slackResult?.dm_users || []),
        ].map((item) => ({
          id: item.id,
          name: item.name,
        }));

        if (listFor === "Slack") {
          setDropDownData(formatedSlackData);
        } else {
          setDropDownData(formatedDiscordData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    };

    fetchData();
  }, []);

  const copyToClipboard = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success(`ID ${id} copied to clipboard`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy ID");
      });
  };

  if (dropDownData.length > 0)
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="text-black">
            Advanced <FiChevronDown className="ml-2 w-4 h-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-auto max-h-[300px] overflow-y-auto scrollbar-custom mr-4 max-[610px]:ml-4 max-[610px]:mr-0 max-[610px]:text-sm ">
          <style>{scrollbarStyles}</style>
          {dropDownData.map((item) => (
            <div
              key={item.id}
              className="flex gap-2 justify-between items-center p-2 hover:bg-accent hover:text-accent-foreground"
            >
              <span>{item.name}</span>
              <div
                className="flex items-center cursor-pointer"
                onClick={() => copyToClipboard(item.id)}
              >
                <span className="mr-2">{item.id}</span>
                <FiCopy className="w-4 h-4" />
              </div>
            </div>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
};

export default ChannelsDropdown;
