import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";
import { Button } from "../components/button";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import toast from "react-hot-toast";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../components/select";
import apiVisitorFilters from "./VisitorsApis/apiVisitorFilters";

export default function VisitorsFiltersCard({ onClose, setFilters, filters }) {
  const [listOfFilters, setListOfFilters] = useState({});
  const [rows, setRows] = useState([
    { selectedKey: null, selectedValue: null },
  ]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const data = await apiVisitorFilters();
        setListOfFilters(data.filters); // Assume data.filters is an object
      } catch (error) {
        console.error(error);
      }
    };

    fetchFilters();
  }, []);

  // Populate rows if filters are passed
  useEffect(() => {
    if (filters) {
      const initialRows = Object.keys(filters).map((key) => ({
        selectedKey: key,
        selectedValue: filters[key][0], // Assuming you want the first value
      }));
      setRows(initialRows);
    }
  }, [filters]);

  // Handler to update a row's selectedKey or selectedValue
  const handleRowChange = (index, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [field]: value } : row
      )
    );
  };

  // Handler to add a new row
  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { selectedKey: null, selectedValue: null },
    ]);
  };

  // Handler to remove a row
  const handleRemoveRow = (index) => {
    setRows((prevRows) => prevRows.filter((_, rowIndex) => rowIndex !== index));
  };

  // Handler for submit
  const handleSubmit = () => {
    const result = rows.reduce((acc, row) => {
      if (row.selectedKey && row.selectedValue) {
        acc[row.selectedKey] = acc[row.selectedKey]
          ? [...acc[row.selectedKey], row.selectedValue]
          : [row.selectedValue];
      }
      return acc;
    }, {});

    if (Object.keys(result).length === 0) {
      // No filters to submit; consider this "clear filters."
      toast.success("All filters cleared!");
      setFilters(null); // Optional: Reset the filters if needed
      onClose(); // Close the dialog
      return;
    }

    // Filters are present, pass them to the setFilters function
    setFilters(result);

    // Close the dialog after setting the filters
    onClose();

    // Optional: Remove the toast success message if not needed
    toast.success("Filters submitted successfully!");
  };
  return (
    <Card className="mx-auto w-full max-w-2xl rounded-lg border-0 shadow-none max-h-[80vh] overflow-y-auto">
      <CardHeader className="pb-4">
        <CardTitle className="text-xl">Visitor Filters</CardTitle>
      </CardHeader>
      <CardContent className="pb-2 space-y-4">
        {rows.map((row, index) => (
          <div
            key={index}
            className="flex flex-col items-start space-y-2 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2"
          >
            {/* First Select: Keys */}
            <div className="flex-1 w-full sm:w-auto">
              <Select
                value={row.selectedKey || undefined}
                onValueChange={(value) =>
                  handleRowChange(index, "selectedKey", value)
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a filter key" />
                </SelectTrigger>
                <SelectContent
                  className="overflow-y-auto max-h-60"
                  onWheel={(e) => e.stopPropagation()}
                >
                  <SelectGroup>
                    <SelectLabel>Filter Keys</SelectLabel>
                    {Object.keys(listOfFilters).map((key) => (
                      <SelectItem key={key} value={key}>
                        {key}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <span className="hidden px-2 text-2xl font-bold sm:inline">=</span>

            {/* Second Select: Values */}
            <div className="flex-1 w-full sm:w-auto">
              <Select
                disabled={!row.selectedKey}
                value={row.selectedValue || undefined}
                onValueChange={(value) =>
                  handleRowChange(index, "selectedValue", value)
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a value" />
                </SelectTrigger>
                <SelectContent
                  className="overflow-y-auto max-h-60"
                  onWheel={(e) => e.stopPropagation()}
                >
                  <SelectGroup>
                    <SelectLabel>Values</SelectLabel>
                    {row.selectedKey &&
                      (row.selectedKey === "online"
                        ? ["true", "false"].map((value) => (
                            <SelectItem key={value} value={value}>
                              {value}
                            </SelectItem>
                          ))
                        : listOfFilters[row.selectedKey]?.map((value, idx) => (
                            <SelectItem key={idx} value={value}>
                              {value}
                            </SelectItem>
                          )))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            {/* Remove Row Button */}
            <Button
              variant="ghost"
              onClick={() => handleRemoveRow(index)}
              className="mt-2 w-full text-red-500 hover:text-red-700 sm:mt-0 sm:w-auto"
            >
              <AiOutlineClose className="mr-2 w-4 h-4 sm:mr-0" />
              <span className="sm:hidden">Remove filter</span>
            </Button>
          </div>
        ))}
        <Button
          variant="outline"
          className="mt-4 w-full"
          onClick={handleAddRow}
        >
          <AiOutlinePlus className="mr-2 w-4 h-4" /> Add Filter
        </Button>
      </CardContent>
      <CardFooter className="pt-6">
        <Button className="w-full" onClick={handleSubmit}>
          {rows.length === 0 ? "Clear Filters" : "Apply Filters"}
        </Button>
      </CardFooter>
    </Card>
  );
}
