import { createContext, useEffect, useState, useRef } from "react";
import Sidebar from "../ui/Sidebar";
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import apiCheckForCalling from "../Signup/apiCheckForCalling";
import apiDeclineCall from "../Signup/apiDeclineCall";
import { IoMdClose } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
export const AppLayoutContext = createContext();

// function AppLayout() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const navigate = useNavigate();

//   const fetchCallingStatus = async () => {
//     try {
//       const data = await apiCheckForCalling();
//       setPendingRequests(data.call_requests);
//     } catch (error) {
//       console.error("Error fetching calling status:", error);
//     }
//   };

//   useEffect(() => {
//     const intervalId = setInterval(fetchCallingStatus, 5000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const acceptCall = async () => {
//     if (pendingRequests[0]) {
//       await fetchCallingStatus();
//       navigate(
//         "/visitors/" + pendingRequests[0].visitor_id + "?callRequest=true"
//       );
//     }
//   };

//   const declineCall = async () => {
//     try {
//       setPendingRequests([]);
//       const data = await apiDeclineCall(pendingRequests[0].visitor_id);
//     } catch (error) {
//       console.error("Error fetching calling status:", error);
//     }
//   };

//   return (
//     <AppLayoutContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
//       <div className="grid lg:grid-cols-[13rem_1fr] h-dvh grid-cols-1 relative">
//         <div
//           className={`${
//             sidebarOpen
//               ? "overflow-hidden absolute z-50 w-full h-screen bg-white"
//               : "hidden"
//           } lg:block`}
//         >
//           <Sidebar />
//         </div>

//         <main className="overflow-auto p-6 m-0 bg-gray-100 rounded-xl shadow-lg lg:p-8 lg:m-5">
//           {pendingRequests[0] && (
//             <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
//               <p>
//                 Visitor {pendingRequests[0].visitor_name} is trying to call you!
//               </p>
//               <div className="flex gap-4">
//                 <button
//                   className="p-4 bg-green-500 rounded-lg"
//                   onClick={acceptCall}
//                 >
//                   Accept
//                 </button>
//                 <button
//                   className="p-4 bg-red-500 rounded-lg"
//                   onClick={declineCall}
//                 >
//                   Decline
//                 </button>
//               </div>
//             </div>
//           )}

//           <Outlet />
//         </main>
//       </div>
//     </AppLayoutContext.Provider>
//   );
// }

// function AppLayout() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const fetchCallingStatus = async () => {
//     try {
//       const data = await apiCheckForCalling();
//       setPendingRequests(data.call_requests);
//     } catch (error) {
//       console.error("Error fetching calling status:", error);
//     }
//   };

//   useEffect(() => {
//     const intervalId = setInterval(fetchCallingStatus, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     fetchCallingStatus();
//   }, [location]);

//   const acceptCall = async () => {
//     if (pendingRequests.length > 0) {
//       const acceptedRequest = pendingRequests[0];
//       // Remove the accepted request from the list
//       setPendingRequests((prevRequests) => prevRequests.slice(1));
//       navigate(`/visitors/${acceptedRequest.visitor_id}?callRequest=true`);
//     }
//   };

//   const declineCall = async () => {
//     if (pendingRequests.length > 0) {
//       try {
//         const declinedRequest = pendingRequests[0];
//         // Remove the declined request from the list
//         setPendingRequests((prevRequests) => prevRequests.slice(1));
//         await apiDeclineCall(declinedRequest.visitor_id);
//       } catch (error) {
//         console.error("Error declining call:", error);
//       }
//     }
//   };

//   return (
//     <AppLayoutContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
//       <div className="grid lg:grid-cols-[13rem_1fr] h-dvh grid-cols-1 relative">
//         <div
//           className={`${
//             sidebarOpen
//               ? "overflow-hidden absolute z-50 w-full h-screen bg-white"
//               : "hidden"
//           } lg:block`}
//         >
//           <Sidebar />
//         </div>

//         <main className="overflow-auto p-6 m-0 bg-gray-100 rounded-xl shadow-lg lg:p-8 lg:m-5">
//           {pendingRequests.length > 0 && (
//             <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
//               <p>
//                 Visitor {pendingRequests[0].visitor_name} is trying to call you!
//               </p>
//               <div className="flex gap-4">
//                 <button
//                   className="p-4 bg-green-500 rounded-lg"
//                   onClick={acceptCall}
//                 >
//                   Accept
//                 </button>
//                 <button
//                   className="p-4 bg-red-500 rounded-lg"
//                   onClick={declineCall}
//                 >
//                   Decline
//                 </button>
//               </div>
//             </div>
//           )}

//           <Outlet />
//         </main>
//       </div>
//     </AppLayoutContext.Provider>
//   );
// }

// function AppLayout() {
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [sidebarHovered, setSidebarHovered] = useState(false);
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const navigate = useNavigate();
//   const location = useLocation();

//   const fetchCallingStatus = async () => {
//     try {
//       const data = await apiCheckForCalling();
//       setPendingRequests(data.call_requests);
//     } catch (error) {
//       console.error("Error fetching calling status:", error);
//     }
//   };

//   useEffect(() => {
//     const intervalId = setInterval(fetchCallingStatus, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     fetchCallingStatus();
//   }, [location]);

//   const acceptCall = async () => {
//     if (pendingRequests.length > 0) {
//       const acceptedRequest = pendingRequests[0];
//       setPendingRequests((prevRequests) => prevRequests.slice(1));
//       navigate(`/visitors/${acceptedRequest.visitor_id}?callRequest=true`);
//     }
//   };

//   const declineCall = async () => {
//     if (pendingRequests.length > 0) {
//       try {
//         const declinedRequest = pendingRequests[0];
//         setPendingRequests((prevRequests) => prevRequests.slice(1));
//         await apiDeclineCall(declinedRequest.visitor_id);
//       } catch (error) {
//         console.error("Error declining call:", error);
//       }
//     }
//   };

//   return (
//     <AppLayoutContext.Provider
//       value={{ sidebarOpen, setSidebarOpen, sidebarHovered, setSidebarHovered }}
//     >
//       <div className="flex relative h-dvh">
//         <Sidebar />
//         <main className="overflow-auto flex-1 p-6 m-0 bg-gray-100 rounded-xl shadow-lg lg:p-8 lg:m-5">
//           <div className="flex justify-between items-center mb-4">
//             <button
//               className="z-50 p-2 bg-white rounded-full shadow-md lg:hidden"
//               onClick={() => setSidebarOpen(!sidebarOpen)}
//             >
//               {sidebarOpen ? (
//                 <IoMdClose size={24} />
//               ) : (
//                 <RxHamburgerMenu size={24} />
//               )}
//             </button>
//           </div>
//           {pendingRequests.length > 0 && (
//             <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
//               <p>
//                 Visitor {pendingRequests[0].visitor_name} is trying to call you!
//               </p>
//               <div className="flex gap-4">
//                 <button
//                   className="p-4 bg-green-500 rounded-lg"
//                   onClick={acceptCall}
//                 >
//                   Accept
//                 </button>
//                 <button
//                   className="p-4 bg-red-500 rounded-lg"
//                   onClick={declineCall}
//                 >
//                   Decline
//                 </button>
//               </div>
//             </div>
//           )}
//           <Outlet />
//         </main>
//       </div>
//     </AppLayoutContext.Provider>
//   );
// }

function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [debounce, setDebounce] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1024); // Adjust this breakpoint as needed
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const fetchCallingStatus = async () => {
    try {
      const data = await apiCheckForCalling();
      setPendingRequests(data.call_requests);
    } catch (error) {
      console.error("Error fetching calling status:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchCallingStatus, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (searchParams.get("callRequest") !== "true") {
      fetchCallingStatus();
    }
  }, [location]);

  const acceptCall = async () => {
    if (pendingRequests.length > 0) {
      const acceptedRequest = pendingRequests[0];
      const currentPath = window.location.pathname;
      // Remove the accepted request from the list
      setPendingRequests((prevRequests) => prevRequests.slice(1));

      if (currentPath.includes(`/visitors/${acceptedRequest.visitor_id}`)) {
        const timestamp = new Date().getTime();
        navigate(
          `/visitors/${acceptedRequest.visitor_id}?callRequest=true&refresh=${timestamp}`
        );
      } else {
        navigate(`/visitors/${acceptedRequest.visitor_id}?callRequest=true`);
      }
      setDebounce(true);
      setTimeout(() => setDebounce(false), 4000);
    }
  };

  const declineCall = async () => {
    if (pendingRequests.length > 0) {
      try {
        const declinedRequest = pendingRequests[0];
        setPendingRequests((prevRequests) => prevRequests.slice(1));
        await apiDeclineCall(declinedRequest.visitor_id);
      } catch (error) {
        console.error("Error declining call:", error);
      }
    }
  };

  // return (
  //   <AppLayoutContext.Provider
  //     value={{
  //       sidebarOpen,
  //       setSidebarOpen,
  //       sidebarHovered,
  //       setSidebarHovered,
  //       isMobile,
  //     }}
  //   >
  //     <div className="flex h-dvh">
  //       <Sidebar />
  //       <div className="flex flex-col flex-1">
  //         <main className="overflow-auto flex-1 p-6 bg-gray-100">
  //           {pendingRequests.length > 0 && (
  //             <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
  //               <p>
  //                 Visitor {pendingRequests[0].visitor_name} is trying to call
  //                 you!
  //               </p>
  //               <div className="flex gap-4">
  //                 <button
  //                   className="p-4 bg-green-500 rounded-lg"
  //                   onClick={acceptCall}
  //                 >
  //                   Accept
  //                 </button>
  //                 <button
  //                   className="p-4 bg-red-500 rounded-lg"
  //                   onClick={declineCall}
  //                 >
  //                   Decline
  //                 </button>
  //               </div>
  //             </div>
  //           )}
  //           <Outlet />
  //         </main>
  //       </div>
  //     </div>
  //   </AppLayoutContext.Provider>
  // );

  return (
    <AppLayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        sidebarHovered,
        setSidebarHovered,
        isMobile,
      }}
    >
      <div className="flex h-dvh">
        <Sidebar />
        <div className="flex overflow-hidden flex-col flex-1">
          <main className="overflow-auto flex-1 bg-gray-100">
            <div className="p-6">
              {!debounce && pendingRequests.length > 0 && (
                <div className="flex justify-between items-center p-4 mb-4 w-full text-center text-white bg-green-800 rounded-lg">
                  <p>
                    Visitor {pendingRequests[0].visitor_name} is trying to call
                    you!
                  </p>
                  <div className="flex gap-4">
                    <button
                      className="p-4 bg-green-500 rounded-lg"
                      onClick={acceptCall}
                    >
                      Accept
                    </button>
                    <button
                      className="p-4 bg-red-500 rounded-lg"
                      onClick={declineCall}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              )}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </AppLayoutContext.Provider>
  );
}

export default AppLayout;
