const { useState } = require("react");

const WorkflowToggleSwitch = ({ isOn, workflowId, onToggle }) => {
  const [checked, setChecked] = useState(isOn);

  const handleToggle = async () => {
    setChecked(!checked);
    await onToggle(workflowId, !checked);
  };

  return (
    <button
      onClick={handleToggle}
      className={`relative inline-flex items-center h-7 rounded-full w-16 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
        checked ? "bg-black" : "bg-gray-200"
      }`}
    >
      <span
        className={`${
          checked ? "translate-x-10" : "translate-x-1"
        } inline-block w-5 h-5 transform bg-white rounded-full transition-transform`}
      />
      <span
        className={`absolute inset-0 flex items-center ${
          checked ? "justify-start pl-2" : "justify-end pr-2"
        } text-xs font-semibold ${checked ? "text-white" : "text-gray-900"}`}
      >
        {checked ? "ON" : "OFF"}
      </span>
    </button>
  );
};

export default WorkflowToggleSwitch;
