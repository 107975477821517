import { Avatar, AvatarFallback, AvatarImage } from "../components/avatar";
import { Button } from "../components/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/table";
import { Badge } from "../components/badge";
import {
  FiMoreHorizontal,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import apiVisitors from "./VisitorsApis/apiVisitors";
import { useEffect, useRef, useState } from "react";
import Spinner from "../ui/Spinner";
import { Card, CardContent } from "../components/card";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const calculateLastLogin = (lastLoginString) => {
  const lastLoginDate = new Date(lastLoginString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - lastLoginDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} s ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} m ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} h ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} d ago`;
  }
};

const flagemojiToPNG = (countryCode) => {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`}
      alt="flag"
    />
  );
};

const sortVisitors = (visitors) => {
  return [...visitors].sort((a, b) => {
    if (a.online && !b.online) return -1;
    if (!a.online && b.online) return 1;
    if (a.online === b.online) {
      if (a.online) {
        // For online users, prioritize those with data.name or data.email
        if ((a.data?.name || a.data?.email) && !(b.data?.name || b.data?.email))
          return -1;
        if (!(a.data?.name || a.data?.email) && (b.data?.name || b.data?.email))
          return 1;
      } else {
        // For offline users, sort by last_login
        return new Date(b.last_login) - new Date(a.last_login);
      }
    }
    return 0;
  });
};

const VisitorsTable = ({ filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visitors, setVisitors] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page") || "1", 10)
  );
  const [totalPages, setTotalPages] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);

  const fetchVisitors = async (page) => {
    try {
      setIsLoading(true);
      const data = await apiVisitors(page, 6, filters); // Changed to 6 items per page
      setTotalVisitors(data.total_visitors);
      setTotalPages(Math.ceil(data.total_visitors / 6));

      if (data.visitors.length === 0 && page > 1) {
        // If no data for this page and it's not the first page, redirect to the last valid page
        const lastValidPage = Math.ceil(data.total_visitors / 6);
        setSearchParams({ page: lastValidPage.toString() });
        return fetchVisitors(lastValidPage);
      }

      return data.visitors;
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || "1", 10);
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchVisitors(currentPage);
      setVisitors(data);
    };
    fetchData();
  }, [currentPage, filters]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setSearchParams({ page: newPage.toString() });
    }
  };

  if (isLoading) {
    return (
      <div className="mt-20">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="py-2 w-full">
      <Table className="bg-transparent md:bg-white">
        <TableHeader className="hidden pb-4 md:table-row-group">
          <TableRow className="hover:bg-white">
            <TableHead className="w-[300px]">CUSTOMER</TableHead>
            <TableHead>LAST ACTIVE</TableHead>
            <TableHead>LOCATION </TableHead>
            <TableHead># SESSIONS</TableHead>
            <TableHead>FIRST VISIT </TableHead>
            <TableHead>DEVICE </TableHead>
            <TableHead>LAST PAGE </TableHead>
            <TableHead className="sticky -right-5 z-10 pl-0 bg-white">
              <div className=" border-l-4 h-[48px] border-gray-100 flex justify-center items-center">
                ACTIONS
              </div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {visitors.map((customer) => (
            <TableRow
              key={customer._id}
              className="grid grid-cols-1 p-[0.5rem] min-[330px]:p-4 mb-5 bg-white rounded-lg border-b-2 md:rounded-none md:bg-transparent md:table-row md:border-0 hover:bg-white md:hover:bg-gray-50"
            >
              <TableCell className="order-2 font-medium">
                <div className="flex items-center md:space-x-4">
                  <Avatar className="hidden md:block">
                    <AvatarImage
                      src={customer?.avatarUrl}
                      alt={customer.data?.name || "user avatar"}
                    />
                    <AvatarFallback>
                      {customer?.name ? customer?.name.charAt(0) : "X"}
                    </AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="font-semibold">
                      {customer?.name || customer._id}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {customer.data?.email || customer.ip}
                    </div>
                  </div>
                </div>
              </TableCell>
              <TableCell className="order-1 w-full md:w-auto">
                <div className="flex justify-between md:w-[5rem] md:gap-2 md:flex-col md:justify-normal md:items-center">
                  <Badge
                    variant={
                      customer.online === false ? "secondary" : "default"
                    }
                    className="flex justify-center items-center px-0 w-20 text-base"
                  >
                    {customer.online ? "online" : "offline"}
                  </Badge>
                  <span className="text-sm text-muted-foreground">
                    {!customer.online &&
                      calculateLastLogin(customer.last_online)}
                  </span>
                </div>
              </TableCell>
              <TableCell className="flex order-3 justify-between md:order-none md:hidden">
                <Card className="overflow-hidden w-full max-w-md border-none shadow-none">
                  <CardContent className="p-0">
                    <div className="grid grid-cols-2 gap-5 text-sm">
                      <div>
                        <p className="text-muted-foreground">Country:</p>
                        <div className="flex gap-2">
                          <span className="font-semibold">
                            {customer.location.country}
                          </span>
                          {flagemojiToPNG(customer.location.country)}
                        </div>
                      </div>
                      <div>
                        <p className="text-muted-foreground">City:</p>
                        <p className="font-semibold">
                          {customer.location.city}
                        </p>
                      </div>
                      <div>
                        <p className="text-muted-foreground"># of sessions:</p>
                        <p className="font-semibold">
                          {customer.total_browsing_sessions}
                        </p>
                      </div>
                      <div>
                        <p className="text-muted-foreground">Device name:</p>
                        <p className="font-semibold">{customer.device.name}</p>
                      </div>
                      <div>
                        <p className="text-muted-foreground">Device type:</p>
                        <p className="font-semibold">{customer.device.type}</p>
                      </div>
                      <div>
                        <p className="text-muted-foreground">Last login:</p>
                        <p className="font-semibold">
                          {formatDate(customer.created_at)}
                        </p>
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="text-muted-foreground">
                        Last page visited:
                      </p>
                      <p className="text-xs font-semibold break-all">
                        {customer.current_page}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p className="flex gap-2 mb-2">
                  <span>{customer.location.country}</span>
                  {flagemojiToPNG(customer.location.country)}
                </p>
                <p>{customer.location.city}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{customer.total_browsing_sessions}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{formatDate(customer.created_at)}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{customer.device.name}</p>
                <p>{customer.device.type}</p>
              </TableCell>

              <TableCell className="hidden md:table-cell">
                <p>{customer.current_page}</p>
              </TableCell>

              <TableCell className="sticky -right-5 z-10 order-4 p-0 m-0 w-full text-right bg-white md:w-auto">
                <div className="flex justify-center items-center p-4 min-[768px]:border-l-4 min-[768px]:pr-[20px] min-[768px]:h-[115px] min-[768px]:border-l-gray-100 ">
                  <Button
                    variant="outline"
                    size="sm"
                    className={`w-full ${
                      customer.online === true
                        ? "bg-green-500 hover:bg-green-600"
                        : "bg-black text-white hover:text-white hover:bg-gray-900"
                    }  md:w-28`}
                  >
                    <Link to={`/visitors/${customer._id}`}>
                      {customer.online === true ? "Watch Live" : "View history"}
                    </Link>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Pagination logic here ------------------------------------------ */}
      <div className="flex justify-end items-center py-4 space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FiChevronLeft className="w-4 h-4" />
        </Button>

        <p className="text-sm text-muted-foreground">
          {currentPage} of {totalPages}
        </p>

        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FiChevronRight className="w-4 h-4" />
        </Button>
      </div>
      {/* Pagination logic here ------------------------------------------ */}
    </div>
  );
};

export default VisitorsTable;
