import { RxHamburgerMenu } from "react-icons/rx";
import WorkflowsTable from "../Workflows/WorkflowsTable";
import { useContext, useEffect, useState } from "react";
import { AppLayoutContext } from "./AppLayout";
import WorkflowPopup from "../Workflows/WorkflowPopup";
import {
  WorkflowContext,
  WorkflowProvider,
} from "../Workflows/WorkflowContextProvider";

function Workflows() {
  const { setSidebarOpen } = useContext(AppLayoutContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { workflows, fetchWorkflows } = useContext(WorkflowContext);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-bold pointer-events-none">Workflows</p>
          {workflows.length > 0 && <p> {workflows.length} Workflows</p>}
        </div>

        <button
          className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-transparent hover:border-black active:border-black lg:hidden"
          onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
        >
          <RxHamburgerMenu />
        </button>
      </div>
      <div className="flex flex-col gap-4 items-end">
        <button
          className="px-5 py-2 font-semibold text-white bg-black rounded-lg"
          onClick={() => setIsPopupOpen(true)}
        >
          New Workflow
        </button>
        <WorkflowsTable />
      </div>
      {isPopupOpen && <WorkflowPopup handleCancel={setIsPopupOpen} />}
    </>
  );
}

export default Workflows;
